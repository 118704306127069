<template>
  <div class="xcx-preview">
    <!-- <span class="el-icon-close" @click="$parent.showPreview = false"></span> -->
    <div
      class="xcx-module-container"
      v-for="(data, index) in list"
      :key="index"
    >
      <!--控件部份-->
      <div v-for="(item, index) in data.controlList" :key="index">
        <!--页面背景-->
        <pagebg :base="item.base"></pagebg>
      </div>

      <div class="xcx-module">
        <!--DIY组件部份-->
        <Module :moduleList="data.moduleList" :prev="true" ref="module" />
      </div>
    </div>

    <div style="height: 20px">
      <!-- 占位符 -->
    </div>

    <NavModule></NavModule>

    <!-- 首页弹框 -->
    <van-dialog v-model="noticeVisible" show-cancel-button>
      <div
        class="notice-popup-content"
        style="max-height: 80vh; overflow-y: auto"
      >
        <h3 class="notice-title">首页公告</h3>
        <div class="ql-editor" v-html="noticeContent" />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Module from "@/components/Module/mobile/Module.vue";
// import conTitle from "@/pages/template/mobile/module/contitle.vue";
import pagebg from "@/pages/template/mobile/module/pagebg.vue";
import { getWebTemplate, getIndexNotice } from "../api/index";
import NavModule from "@/pages/mobile/common/navModule.vue";

export default {
  name: "mindex",
  components: { Module, pagebg, NavModule },
  data() {
    return {
      list: [],
      noticeVisible: false,
      noticeContent: undefined,
    };
  },

  created() {
    this.initData();
    //加载首页弹框公告
    this.getNotice();
  },
  methods: {
    //初始化页面
    initData() {
      let data = {};
      getWebTemplate(data).then((response) => {
        if (response.status == 0) {
          this.list = JSON.parse(response.data);
        }
      });
    },

    /**
     * 查询首页弹框公告
     */
    getNotice() {
      getIndexNotice().then((response) => {
        if (response.result) {
          this.noticeVisible = true;
          this.noticeContent = response.result.content;
          console.log(response);
        }
      });
    },
  },
};
</script>
<style scoped>
.notice-popup-content {
  padding: 20px;
  text-align: center;
}

.notice-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notice-text {
  color: #999;
  line-height: 32px;
}
</style>
