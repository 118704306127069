import { render, staticRenderFns } from "./productList.vue?vue&type=template&id=41bce7fa&scoped=true&"
import script from "./productList.vue?vue&type=script&lang=js&"
export * from "./productList.vue?vue&type=script&lang=js&"
import style0 from "./productList.vue?vue&type=style&index=0&id=41bce7fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41bce7fa",
  null
  
)

export default component.exports