<template>
  <div>
    <van-nav-bar
      title="资金明细"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div>
      <div class="use-money-detail">
        <div class="scroll-box">
          <div class="uni-scroll-view">
            <div class="uni-scroll-view" style="overflow: hidden auto">
              <div class="uni-scroll-view-content">
                <!---->
                <div class="content">
                  <div
                    class="item"
                    v-for="item in tableData"
                    :key="item.id"
                    :value="item.amount"
                  >
                    <div class="left">
                      <uni-text class="title">
                        <span>{{ item.eventText }}</span>
                      </uni-text>
                      <uni-text class="time">
                        <span>{{ item.datelineReadable }}</span>
                      </uni-text>
                    </div>
                    <div class="right">{{ item.amount }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="item in tableData"
          :key="item.id"
          :value="item.amount"
        >
          <template #title>
            <div>{{ item.eventText }}</div>
          </template>
          <van-cell title="订单号" :value="item.serialNum" />
          <van-cell title="收支类型" :value="item.typeText" />
          <van-cell title="备注" :value="item.remarks" />
          <van-cell title="交易时间" :value="item.datelineReadable" />
        </van-collapse-item>
      </van-collapse> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      activeNames: ["1"],

      tableData: [],
      loadingState: false,
      finished: false,
      queryParam: {
        // pageNum: 1,
        // pageSize: 10,
      },
    };
  },
  computed: {
    ...mapState({
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      statusText: (state) => state.user.statusText,
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$store.dispatch("balance/list", this.queryParam).then(() => {
        this.tableData = this.tableData.concat(this.tableDatas);
      });
    },

    //跳转到我的页面
    onClickLeft() {
      console.log("_-------------");
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
.use-money-detail {
  /* width: 23.4375rem; */
  height: 100vh;
  box-sizing: border-box;
  padding-top: 0.9375rem;
}

.use-money-detail .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.125rem;
  width: 100%;
  padding: 0 1.1875rem;
  box-sizing: border-box;
}

.use-money-detail .header .search-input {
  box-sizing: border-box;
  width: 16.875rem;
  height: 2.125rem;
  background: #ffffff;
  border-radius: 1.0625rem;
  padding: 0 0.84375rem;
}

.use-money-detail .scroll-box {
  width: 23.4375rem;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 70px);
}

.use-money-detail .scroll-box .content {
  padding: 0 0.5625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.625rem;
}

.use-money-detail .scroll-box .content .item {
  display: flex;
  width: 100%;
  height: 4.375rem;
  background: #ffffff;
  border-radius: 0.46875rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.90625rem;
}

.use-money-detail .scroll-box .content .item .left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.375rem;
}

.use-money-detail .scroll-box .content .item .left .title {
  color: #1e2331;
  font-weight: 500;
  font-size: 0.9375rem;
}

.use-money-detail .scroll-box .content .item .left .time {
  color: #c4c7cf;
  font-weight: 400;
  font-size: 0.75rem;
}

.use-money-detail .scroll-box .content .item .right {
  font-weight: 500;
  font-size: 1.25rem;
  color: #1e2331;
}

.use-money-detail .poput-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.use-money-detail .poput-form .form-item {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.03125rem solid #f5f7faff;
  font-weight: 400;
  color: #1e2331;
  font-size: 0.9375rem;
}

.use-money-detail .poput-form .form-item:active {
  background-color: #c4c7cf;
}
</style>
