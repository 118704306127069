<template>
  <div class="user-money-more">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />

    <div class="user-money">
      <div class="more">
        <div class="more-money">
          <div class="title">可用余额</div>
          <span>{{ balanceTotal }}</span>
        </div>

        <van-tabs v-model="active">
          <van-tab title="在线充值">
            <div style="padding: 10px; background-color: #f5f7fa">
              <div class="recharge-box">
                <div class="title">
                  <uni-text class="label"><span>账户充值</span></uni-text>
                </div>
                <div class="form-item">
                  <uni-text class="label"><span>￥</span></uni-text>
                  <div class="my-input-out">
                    <van-cell-group>
                      <van-field
                        v-model="amount"
                        placeholder="请输入要充值的金额"
                      />
                    </van-cell-group>
                  </div>
                </div>
                <uni-text class="tips">
                  <span>手续费:{{ payCommission }}%</span>
                </uni-text>
              </div>

              <div class="more-wrap" style="margin-top: 10px">
                <!-- <div class="input">
                  <span>充值金额</span>

                  <input
                    v-model="amount"
                    type="text"
                    class="money-mun"
                    placeholder="请输入要充值的金额"
                  />
                </div>

                <div style="color: #a2a2a2; padding: 20px 0px 30px 0px">
                  充值金额: {{ amount }}元
                  <span v-if="this.payCommission != undefined"
                    >手续费：{{ payCommission }}%</span
                  >
                </div> -->

                <div>
                  <van-radio-group v-model="payType">
                    <van-cell-group>
                      <van-cell
                        @click="selectPayType(value)"
                        v-for="(value, key, index) in rechargeList"
                        :key="index"
                      >
                        <template #title>
                          <!-- <img
                      src="@/assets/icon/icon_weixin.svg"
                      draggable="false"
                      style="width: 1.5625rem; height: 1.5625rem"
                    /> -->
                          <!-- <van-icon name="gold-coin-o" size="15" /> -->
                          <span class="custom-title" style="font-size: 14px">
                            {{ value.payName }}
                          </span>
                        </template>
                        <template #right-icon>
                          <van-radio :name="value.payType" />
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </van-radio-group>
                </div>
                <div class="tips">
                  <p>充值后请查看是否到账，充值遇到问题请<a>联系客服</a></p>
                </div>
              </div>

              <div style="margin-top: 10px">
                <van-button type="primary" block @click="handleGoBuy()"
                  >立即充值</van-button
                >
              </div>
            </div>
          </van-tab>
          <van-tab title="加款卡兑换">
            <div class="more-wrap" style="padding: 10px">
              <div>
                <van-cell-group>
                  <van-field
                    v-model="plusCdk"
                    label="加款卡"
                    placeholder="请输入加款卡"
                  />
                </van-cell-group>
              </div>
              <div class="tips">
                <p>兑换请查看是否到账，兑换遇到问题请<a>联系客服</a></p>
              </div>

              <div style="margin-top: 10px">
                <van-button type="primary" block @click="upCardRecharge()"
                  >立即兑换</van-button
                >
              </div>
            </div>
          </van-tab>
          <!-- <van-tab title="提现">
            <div class="more-wrap">
              <van-empty description="大额提现请联系客服~" />
            </div>
          </van-tab> -->
        </van-tabs>
      </div>
    </div>

    <Payment
      ref="paymentComponent"
      :amount="this.amount"
      :paymentMethod="this.payType"
      :isMobile="true"
    ></Payment>
  </div>
</template>

<script>
import "@/assets/css/style.css";
import { mapGetters, mapState } from "vuex";
import { camiRecharge } from "@/api/recharge";
import Payment from "@/components/payment/RechargePayment";
import { Toast } from "vant";

export default {
  components: {
    Payment,
  },
  data() {
    return {
      plusCdk: "",
      show: false,
      id: "",
      payCommission: 0,
      amount: undefined,
      rechargeList: [],
      radio: "",
      payType: "",
      payMaps: [],
      qrCodeUrl: "",
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      payMap: (state) => state.order.payMap,
      rechargeConfig: (state) => state.index.rechargeConfig,
      balanceTotal: (state) => state.balance.balanceTotal,
    }),
  },
  created() {
    document.title = "我的钱包";

    //查询我的余额
    this.$store.dispatch("balance/detail");
    //初始化支付通道
    this.initPayConfig();
  },
  methods: {
    //初始化支付通道
    initPayConfig() {
      let data = {};
      this.$store.dispatch("index/queryRechargeConfig", data).then(() => {
        this.rechargeList = this.rechargeConfig.payList;
        // this.$forceUpdate();
      });
    },

    /**
     * 选择支付类型
     */
    selectPayType(v) {
      this.payType = v.payType;
      this.payCommission = v.payCommission;
    },

    /**
     * 充值余额
     */
    handleGoBuy() {
      this.$refs.paymentComponent.toPayment();
    },

    /**
     * 加款卡充值
     */
    upCardRecharge() {
      if (this.plusCdk == "") {
        Toast("请输入加款卡");
        return;
      }
      let data = {
        plusCdk: this.plusCdk,
      };
      new Promise((resolve, rejust) => {
        camiRecharge(data)
          .then((res) => {
            if (res.status == 0) {
              Toast.success("兑换成功!");
            }
            //刷新余额
            this.$store.dispatch("balance/detail");
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 返回上页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>
<style scoped>
.user-money-more {
  background-image: url(../../assets/image/user-money-cashout-bg.png);
  background-size: 25rem;
  /* background-repeat: no-repeat; */
  background-color: #fff;
}
.user-money {
  padding-top: 10px;
}

.user-money .more .more-money {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.user-money .more .more-money .title {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: rgb(255 255 255 / 0.8);
}

.user-money .more .more-money span {
  font-size: 2rem;
  color: #fff;
}

/* .user-money .more .more-type span.hover {
  color: #f38969;
} */

.user-money .more .more-type {
  width: 46%;
  height: 2.8rem;
  /* border-radius: 2.1rem; */
  /* font-size: 2.28rem; */
  /* border: 0.22rem solid #efefef; */
  /* background: #fff; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.user-money .more .more-wrap.hover {
  display: block;
}

.user-money .more .more-wrap {
  /* display: none; */
  /* width: 100%; */
  /* padding: 1rem;
  background: #fff;
  margin-top: 1.4rem;
  border-radius: 0.5rem; */
  /* padding: 1rem; */
  background: #fff;
  /* padding-top: 50px; */
}

.user-money .more .more-wrap .mode {
  margin-top: 2.4rem;
}

.user-money .more .more-wrap .input > span,
.user-money .more .more-wrap .mode > span {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 14px;
}

.user-money .more .more-wrap .tips {
  margin-top: 15px;
  text-align: center;
}

.user-money .more .more-wrap .tips p {
  font-size: 0.8rem;
  color: #ccc;
}

.user-money .more .more-wrap .tips p a {
  margin: 0 0.5rem;
  color: #f38969;
}

.user-money .more .more-wrap .tips p a {
  margin: 0 0.3rem;
  color: #f38969;
}

.user-money .more .more-wrap .input input {
  width: 100%;
  height: 2.5rem;
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  text-indent: 0.2rem;
  font-size: 0.9rem;
}

.but {
  margin-top: 1.4rem;
}

/* 支付弹框 */

.section .zf_container {
  margin: 18px auto 0;
  /* padding-top: 38px; */
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.section .con {
  margin: 3px auto 0;
  padding-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}
.time span {
  font-size: 18px;
  color: #545454;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
}
.time span p {
  color: #3259ff;
  display: inline-block;
}
.order {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: center;
}
.order span:first-child {
  color: #999;
  font-size: 15px;
  margin-left: 14px;
}
.order span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.goods_name {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}
.goods_name span:nth-child(2) {
  margin-left: 14px;
}
.yuanmoney {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: left;
}
.yuanmoney span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.pay_type {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.pay_type img {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
}
.pay_type span {
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
}
.code_cs,
.code {
  height: 208px;
  background: #fbfbfb;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
}
.code_cs {
  height: 208px;
  background: #ffffff;
}
.code_cs img {
  position: absolute;
  width: 49px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  padding: 10px;
}
.code {
  border: 5px solid #d8d1d1;
  border-radius: 5px;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
  display: block;
  padding: 3px;
}
.price {
  color: #386cfa;
  width: 100%;
  text-align: center;
  top: 65px;
}
.price span:first-child {
  font-size: 28px;
}
.price span {
  font-weight: 700;
}
.price span:nth-child(2) {
  font-size: 17px;
}
.price span {
  font-weight: 700;
}
.shanxinzha {
  margin-top: 32px;
  width: 100%;
  text-align: center;
}
.shanxinzha img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  -webkit-animation: xuanzhuan 0.8s linear infinite;
}

.shanxinzha span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.shanxinzha span p {
  display: inline-block;
  color: #386cfa;
}
.section--last {
  margin-bottom: 20px;
}

/* 新的 */

.recharge-box {
  /* width: 22.3125rem; */
  background: #ffffff;
  /* border-radius: 0.46875rem; */
  /* margin-top: 0.8125rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.96875rem 0.8125rem 0;
}

.recharge-box .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 0.875rem;
}

.recharge-box .title .label {
  color: #1e2331;
}

.recharge-box .title .type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.46875rem;
  color: #fd4d20;
}

.recharge-box .title .type .type-change-icon {
  width: 0.9375rem;
  height: 0.8125rem;
}

.recharge-box .title .type:active {
  color: rgba(253, 77, 32, 0.627);
}

.recharge-box .form-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.71875rem;
  border-bottom: 0.03125rem solid #f5f7fa;
  padding: 0.40625rem 0;
}

.recharge-box .form-item .label {
  color: #1e2331;
  font-weight: 400;
  font-size: 1.1875rem;
}

.recharge-box .form-item uni-input {
  width: 100%;
  color: #1e2331;
  font-weight: 600;
  font-size: 2.0625rem;
}

.recharge-box .form-item uni-input .input-placeholder {
  color: #d8d8d8;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
}

.recharge-box .form-item .my-input-out {
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 2.0625rem;
  position: relative;
}

.recharge-box .form-item .my-input-out .input-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0.625rem;
  top: 0;
  display: flex;
  align-items: center;
  color: #d8d8d8;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  pointer-events: none;
}

.recharge-box .tips {
  width: 100%;
  margin: 0.65625rem 0;
  color: #9b9fa8;
  font-size: 0.75rem;
}
</style>
